import React from "react";
import Sidebar from "../components/navigation/Sidebar";

const admin = () => {
  return (
    <React.Fragment>
      <Sidebar />
    </React.Fragment>
  );
};

export default admin;
